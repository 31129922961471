import "./App.css";

import topimg from "./img.svg";
// import { BsGithub } from "react-icons/bs";

function App() {
  return (
    <>
      <div className="w-full h-screen">
        <div className="max-w-[1000px] mx-auto px-32 flex flex-col justify-center h-full">
          <center>
            <img src={topimg} alt="top" width="500px" />
          </center>
          <p className="text-[#3eaff0] text-3xl font-bold">Hey 👋</p>
          <p className="text-gray-200 text-5xl font-bold">Nordix Cloud is</p>
          <p className="text-gray-200 text-5xl font-bold">
            Currently under construction
          </p>
          {/* <div className="pt-5 flex">
            <a
              href="https://github.com/Nolfi24"
              className="text-white text-2xl cursor-pointer hover:text-gray-300"
            >
              <BsGithub />
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default App;
